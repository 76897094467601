// if: lines of code > 300
// then: breakdown this file and move to src/features/Common/utils folder
export const isPositiveNumber = (input) => {
  // tests for positive integers only
  const reg = new RegExp(/^\d*$/);
  return reg.test(input);
};

/**
 * Generates factors of the given number L, excluding L itself if non-prime.
 * @param {number} L - The number for which factors need to be generated.
 * @returns {Array} An array of factors of L (excluding L itself if non-prime).
 */
export const generateFactors = (L) => {
  if (L === 1) {
    return [1];
  }
  const factors = [];
  for (let i = 1; i <= Math.floor(Math.sqrt(L)); i++) {
    if (L % i === 0) {
      factors.push(i);
      if (i !== L / i) {
        factors.push(L / i);
      }
    }
  }

  // Exclude L itself if it's not prime
  const filteredFactors = factors.filter((factor) => factor !== L);
  return filteredFactors.sort((a, b) => a - b);
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// utility that takes an id and scrolls to element with this id
export const onScrollToDiv = ({ id }) => {
  setTimeout(() => {
    const divElement = document.getElementById(id);
    divElement && divElement.scrollIntoView({ behavior: "smooth" });
  }, 10);
};

/**
/* Generates an array of randomise elements everytime.
 * @param {Array} array - The array to be shuffled.
 * @returns {Array} The shuffled array.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const roundOff = (value, places) => {
  if (value % 1 == 0) return value / 1;
  return (Math.round(value * 100) / 100).toFixed(places);
};

export const roundOffIfDecimal = (val, places = 2) => {
  if (Number.isInteger(val)) return parseInt(val);
  return roundOff(val, places);
};

export const getFibonacciNthValue = (n) => {
  let a = 0;
  let b = 1;
  for (let i = 2; i <= n; i++) {
    [a, b] = [b, a + b];
  }
  return n ? b : a;
};
