export const commission_form_keys = {
  commission_percentage: {
    key: "commission_percentage",
    label: "Commission Percentage",
    tooltipText: "Customize your affiliate commission rate to match your goals",
    placeholder: "Enter Commission %",
  },
  conversion_period_type: {
    key: "conversion_period_type",
    label: "Conversion Period",
    tooltipText:
      "The time taken from clicking the affiliate link to making a booking. Only the bookings made during this period will be attributed for the affiliate",
    placeholder: "Select Conversion Period",
  },
  recurring_commission_type: {
    key: "recurring_commission_type",
    label: "Commission for Subscriptions",
    placeholder: "Select",
    tooltipText:
      "Choose on which payments you want to pay commissions in case of a subscription-type offering.",
  },
  instalment_commission_type: {
    key: "instalment_commission_type",
    label: "Commission for Instalments",
    placeholder: "Select",
    tooltipText:
      "Choose on which payments you want to pay commissions in case of a Part-Payment type offering.",
  },
  affiliate_listing_map: {
    key: "affiliate_listing_map",
    label: "Select specific offerings",
    placeholder: "Select Offerings",
  },
  conversion_period_magnitude: {
    key: "conversion_period_magnitude",
    placeholder: "Enter No. of days",
    label: "Number of Days",
    max_days: 365,
  },
  approved_offerings_type: {
    key: "approved_offerings_type",
    label: "Approved Offerings",
    tooltipText:
      "These offerings will be available to affiliates in their portal for promotion.",
    placeholder: "Select Offering",
  },
};

export const CONVERSION_PERIOD_OPTIONS = {
  limited_days: {
    value: 1,
    label: "Number of days",
  },
  lifetime: { value: 4, label: "Lifetime" },
};

export const COMMISION_FOR_SUBSCRIPTION_OPTIONS = {
  first_payment: { value: 1, label: "First payment only" },
  all_recurring_payments: { value: 2, label: "For all payments" },
};

export const COMMISION_FOR_INSTAMENTS_OPTIONS = {
  first_payment: { value: 1, label: "Only for the first payment" },
  all_payments: { value: 2, label: "For all payments" },
};

export const APPROVED_OFFERINGS_OPTIONS = {
  all_offerings: { value: 1, label: "All offerings" },
  specific_offerings: { value: 2, label: "Specific offerings" },
};

export const commission_initial_values = {
  [commission_form_keys.commission_percentage.key]: 10,
  [commission_form_keys.conversion_period_type.key]:
    CONVERSION_PERIOD_OPTIONS.limited_days.value,
  [commission_form_keys.recurring_commission_type.key]:
    COMMISION_FOR_SUBSCRIPTION_OPTIONS.all_recurring_payments.value,
  [commission_form_keys.instalment_commission_type.key]:
    COMMISION_FOR_INSTAMENTS_OPTIONS.all_payments.value,
  [commission_form_keys.approved_offerings_type.key]:
    APPROVED_OFFERINGS_OPTIONS.all_offerings.value,
  [commission_form_keys.affiliate_listing_map.key]: [],
  [commission_form_keys.conversion_period_magnitude.key]: 30,
};
