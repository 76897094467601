import { isInternationalTimezone } from "utils/AuthUtil";

/**
 * @dev
 * Formats a number into a human-readable string using Indian numbering system units.
 * It abbreviates large numbers into Crores (Cr), Lakhs (L), and Thousands (k).
 * The function determines the appropriate unit based on predefined thresholds.
 * @param {number} number - The number to format.
 * @param {boolean} isInternational - Flag indicating whether to apply international time zone formatting.
 * @returns {string} - The formatted number with the appropriate unit.
 */

export function formatNumber(number) {
  const thresholds = [
    { threshold: 1e7, unit: "Cr" },
    { threshold: 1e5, unit: "L" },
    { threshold: 1e3, unit: "k" },
  ];

  for (const { threshold, unit } of thresholds) {
    if (number >= threshold) {
      const formattedNumber = (number / threshold).toFixed(2) + unit;
      return isInternationalTimezone()
        ? formattedNumber + "k"
        : formattedNumber;
    }
  }

  const defaultFormattedNumber = number.toString();
  return isInternationalTimezone()
    ? defaultFormattedNumber + "k"
    : defaultFormattedNumber;
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Check if the value is string
 *
 * @param {string} e - the e to test.
 * @returns {boolean} - boolean true/false.
 */
export const checkIsString = (e) => {
  return typeof e === "string";
};

/**
 * Pads a given number with leading zeros to ensure it has a fixed length.
 *
 * @param {number|string} val - The value to be padded with leading zeros. If a number is provided, it will be converted to a string.
 * @param {number} [numLen=2] - The desired length of the resulting string. Defaults to 2 if not provided.
 * @returns {string} The input value padded with leading zeros to match the specified length.
 *
 * @example
 * getFixedLengthNumString(5);        // '05'
 * getFixedLengthNumString(123, 5);   // '00123'
 * getFixedLengthNumString('9', 3);   // '009'
 */
export const getFixedLengthNumString = (val, numLen = 2) =>
  `00${val}`.slice(-1 * numLen);
export const trimText = (str, maxLength) =>
  str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
