import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { REVIEW_TRANSACTIONS_FOR_PAYOUT_API } from "features/AffiliateMarketing/constants/Affliates.api";
import {
  EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
  EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { useDispatch } from "react-redux";
import { logError } from "utils/error";
import { setSessionStorageItem } from "utils/getterAndSetters";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { isRequestSuccessful } from "utils/Utils";
import { is_empty } from "utils/validations";

export const useAllTransactions = () => {
  const dispatch = useDispatch();
  const { notify } = useNotifications();

  const initiateTransactionsReview = async ({ payload }) => {
    try {
      if (is_empty(payload) || is_empty(payload?.commission_payout_type))
        return;
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
        payload: true,
      });
      const response = await dataProvider.custom_request(
        REVIEW_TRANSACTIONS_FOR_PAYOUT_API,
        apiMethods.POST,
        payload
      );
      const requestId = response?.data?.request_id;
      if (isRequestSuccessful(response.status) && !is_empty(requestId)) {
        setSessionStorageItem(
          SESSION_STORAGE_KEYS.AFFILIATE_PAYOUT_REQUEST_ID,
          requestId
        );
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
          payload: response?.data,
        });
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL,
          payload: true,
        });
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
          payload: false,
        });
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error,
        when: "initiateTransactionsReview",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateTransactions/modules/AllTransactions/utils/useAllTransactions.js",
      });
    }
  };

  return {
    initiateTransactionsReview,
  };
};
