import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";
import { navigateTo } from "utils/urlUtils";

export const AFFILIATE_MARKETING_URL_KEYS = {
  affiliate_marketing: "affiliate_marketing",
  list_affliate_management: "list_affliate_management",
  list_all_transcation: "affiliate_transcations",
  new_user_req: "new_req",
  affliate_detail: "affliate_detail",
  all_transcations: "all_transactions",
  offerings_wise_report: "offerings_wise_report",
  payout_summary: "payout_summary",
  discount_codes: "discount_codes",
  list_all_payout_summary: "transcation/payout_summary",
  review_selection_list_modal: "review_selection_list_modal",
};

export const AFFILIATE_MARKETING_ROUTE_IDS = {
  [AFFILIATE_MARKETING_URL_KEYS.list_affliate_management]:
    "affiliate-marketing/list/approved",
  [AFFILIATE_MARKETING_URL_KEYS.new_user_req]:
    "affiliate-marketing/list/pending",
  [AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing]: "affiliate-marketing",
  [AFFILIATE_MARKETING_URL_KEYS.list_all_transcation]:
    "affiliate-transcation/list/all",
  [AFFILIATE_MARKETING_URL_KEYS.list_all_payout_summary]:
    "affiliate-transcation/list/payout",
  [AFFILIATE_MARKETING_URL_KEYS.affliate_detail]:
    "affliate_detail/:id/all_transactions",
  [AFFILIATE_MARKETING_URL_KEYS.discount_codes]:
    "affliate_detail/:id/discount_code",
  [AFFILIATE_MARKETING_URL_KEYS.payout_summary]:
    "affliate_detail/:id/payout_summary",
  [AFFILIATE_MARKETING_URL_KEYS.offerings_wise_report]:
    "affliate_detail/:id/offering-wise-report",
  [AFFILIATE_MARKETING_URL_KEYS.review_selection_list_modal]:
    "affliate_detail/review_selection_list_modal",
};

export const AFFILIATE_MARKETING_PATHNAMES = {
  [AFFILIATE_MARKETING_URL_KEYS.discount_codes]: (affiliateUuid) =>
    `affliate_detail/${affiliateUuid}/discount_code`,
  [AFFILIATE_MARKETING_URL_KEYS.affliate_detail]: (affiliateUuid) =>
    `affliate_detail/${affiliateUuid}/all_transactions`,
  [AFFILIATE_MARKETING_URL_KEYS.offerings_wise_report]: (affiliateUuid) =>
    `affliate_detail/${affiliateUuid}/offering-wise-report`,
};

const AffiliateMarketing = React.lazy(() =>
  import("features/AffiliateMarketing/AffiliateMarketing").then((module) => ({
    default: module.AffiliateMarketing, // when there is no default export in the component
  }))
);

export const AFFILIATE_MARKETING_CUSTOM_ROUTES = [
  <Route
    exact
    key={`/${
      AFFILIATE_MARKETING_ROUTE_IDS[
        AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing
      ]
    }`}
    path={`/${
      AFFILIATE_MARKETING_ROUTE_IDS[
        AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing
      ]
    }`}
    render={(props) => (
      <LazyLoadRoutes props={props} LazyElement={AffiliateMarketing} />
    )}
  />,
];

export const affiliate_management_back_action = {
  customBackAction: () =>
    navigateTo(AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing),
};

export const affiliate_detail_back_action = {
  customBackAction: () =>
    navigateTo(AFFILIATE_MARKETING_URL_KEYS.list_affliate_management),
};
