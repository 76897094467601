import { validateCommissionPayout } from "../constants/validateCommissionPayout.apiCalls";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";

const useCommissionPayout = () => {
  const { affiliateUuid } = useGetAffiliateUuid();
  const handleCommissionPayout = async ({ payoutType, transaction_uuids }) => {
    try {
      const data = await validateCommissionPayout({
        affiliate_uuid: affiliateUuid,
        commission_payout_type: payoutType,
        transaction_uuids,
      });
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  return handleCommissionPayout;
};

export default useCommissionPayout;
