export const default_checkout_page_color_palette = {
  theme_color_1: "#009DDD",
  theme_color_2: "#1C274C",
  theme_color_3: "#625F72",
  theme_color_4: "#FAFAFA",
};

export const color_palette_config: {
  [key: string]: {
    id: number;
    label: string;
    color_label: keyof typeof default_checkout_page_color_palette;
  };
} = {
  theme_color_1: {
    id: 1,
    label: "Primary Color",
    color_label: "theme_color_1",
  },
  theme_color_2: {
    id: 2,
    label: "Primary Text Color",
    color_label: "theme_color_2",
  },
  theme_color_3: {
    id: 3,
    label: "Secondary Text Color",
    color_label: "theme_color_3",
  },
  theme_color_4: {
    id: 4,
    label: "Background Color",
    color_label: "theme_color_4",
  },
};

export const host_default_color_key = "template-primary-color";
