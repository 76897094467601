import {
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "features/AffiliateMarketing/constants/Affliates.urlPaths";

// for bulk action handling.. useBulkAction need list of uuids
// @muskan.c to improve useBulkAction logic so
export const AFFLIATEDETAIL_RESPONSE_APIS = {
  [AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.affliate_detail]]:
    ({ listData }) => {
      for (let i = 0; i < listData.length; i++) {
        let data = listData[i];
        data.id = data.uuid;
      }
      return { data: listData };
    },
};
