import React, { FC } from "react";
import { Shimmer } from "@my-scoot/component-library-legacy";
import styles from "./LoadingState.module.css";

const LoadingState: FC<{ showActions?: boolean }> = ({ showActions }) => {
  return (
    <div className={styles.root}>
      <Shimmer width="100px" height="21.5px" borderRadius="8px" />

      <Shimmer width="150px" height="19px" borderRadius="8px" />

      <Shimmer borderRadius="8px" wrapper_classname={styles.media} />

      {showActions && (
        <Shimmer
          width="168px"
          height="42px"
          borderRadius="4px"
          wrapper_classname={styles.media}
        />
      )}
    </div>
  );
};

export default LoadingState;
