export const EXLY_ACCOUNT__GET_HIGHLIGHTED_FEATURE =
  "@EXLY_ACCOUNT/GET_HIGHLIGHTED_FEATURE";
export const EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE =
  "@EXLY_ACCOUNT/SET_HIGHLIGHTED_FEATURE";
export const EXLY_ACCOUNT__GET_FEATURE_ACCESSIBILITY =
  "@EXLY_ACCOUNT/GET_FEATURE_ACCESSIBILITY";
export const EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY =
  "@EXLY_ACCOUNT/SET_FEATURE_ACCESSIBILITY";
export const EXLY_ACCOUNT__SET_SHOW_WHITELABEL_UNLOCK_MODAL =
  "@EXLY_ACCOUNT/SET_SHOW_WHITELABEL_UNLOCK_MODAL";
export const EXLY_ACCOUNT__GET_FEATURE_DETAILS =
  "@EXLY_ACCOUNT/GET_FEATURE_DETAILS";
export const EXLY_ACCOUNT__SET_FEATURE_DETAILS =
  "@EXLY_ACCOUNT/SET_FEATURE_DETAILS";
export const EXLY_ACCOUNT__TOGGLE_FAQS_MODAL =
  "@EXLY_ACCOUNT__TOGGLE_FAQS_MODAL";
export const EXLY_ACCOUNT__TOGGLE_SECURITY_PRACTICES_MODAL =
  "@EXLY_ACCOUNT__TOGGLE_SECURITY_PRACTICES_MODAL";
export const EXLY_ACCOUNT__TOGGLE_SET_CREATOR_CONFIG =
  "@EXLY_ACCOUNT__TOGGLE_SET_CREATOR_CONFIG";
