import React from "react";
import ExlyBaseImage from "@my-scoot/exly-react-component-lib/build/esm/features/Common/modules/ExlyImage/ExlyImage";
import { IMAGE_OPTIMIZATION_CDN_URL } from "./constants/ExlyImage.constants";
import { getS3AssetCDNUrl } from "../File/modules/Upload/utils/FileUpload.utils";
import { is_empty } from "features/Common/utils/common.utils";

const ExlyImage = ({ alt = "some-exly-image", src = "", ...restProps }) => {
  const path = restProps?.path;
  let source = src;

  // if bucket paath is provided then fetch image from bucket
  if (!is_empty(path)) {
    source = getS3AssetCDNUrl(path);
  }
  return (
    <ExlyBaseImage
      cdnBaseUrl={IMAGE_OPTIMIZATION_CDN_URL}
      alt={alt}
      src={source}
      {...restProps}
    />
  );
};
export default ExlyImage;
