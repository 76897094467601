import React, { FC } from "react";
import HelpIcon from "@material-ui/icons/Help";
import classnames from "classnames";
import styles from "./LearnMoreTitle.module.css";

const LearnMoreTitle: FC<{
  pageTitle: string;
  href: string;
  about: string;
  className?: string;
}> = ({ href, about, pageTitle, className }) => {
  return (
    <div>
      {pageTitle ? <h1 className={styles.pageTitle}>{pageTitle}</h1> : null}
      <div className={classnames(styles.root, className)}>
        <HelpIcon />{" "}
        <a
          onClick={(e) => e.stopPropagation()}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>{" "}
        {about}
      </div>
    </div>
  );
};

export default LearnMoreTitle;
