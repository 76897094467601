import React from "react";
import { formatNumbeWithCommasString } from "@my-scoot/component-library-legacy";
import styles from "./SelectionCardStyles.module.css";
import classnames from "classnames";
import { pluralise } from "utils/Utils";
import BulkActionButton from "../BulkActionButton/BulkActionButton";
import { getSelectionText } from "./SelectionCard.utils";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import BulkActionDropdown from "features/AffiliateMarketing/modules/BulkActionDropdown";
import { INPUT_TYPE } from "features/BulkActions/BulkActions.constants";

const SelectionCard = ({
  selectedRecords,
  entityName,
  totalRecords,
  actions,
  currentPageFullSelection,
  selectAll,
  handleSelectAll,
  classes = {},
}) => {
  const selectedRecordsLength = selectedRecords?.length || 0;
  if (!selectedRecordsLength && !selectAll) return null;

  const { textWrapperClassName } = classes;
  const showSelectAll =
    totalRecords > DEFAULT_PER_PAGE && currentPageFullSelection && !selectAll;
  return (
    <div className={styles.cardWrapper}>
      <div className={classnames(styles.textWrapper, textWrapperClassName)}>
        <span className={styles.selectionTextStyle}>
          {getSelectionText({
            selectAll,
            selectedRecords,
            entityName,
            totalRecords,
            currentPageFullSelection,
          })}
        </span>
        <span
          className={classnames(styles.selectionTextStyle, styles.linkStyle)}
          onClick={() => handleSelectAll(showSelectAll)}
        >
          {showSelectAll
            ? `Select ${formatNumbeWithCommasString(totalRecords)} ${pluralise(
                entityName,
                totalRecords
              )} across all pages`
            : `Clear selection`}
        </span>
      </div>
      <div className={styles.buttonWrapper}>
        {actions.map((actionItem) =>
          actionItem?.type === INPUT_TYPE.dropdown ? (
            <BulkActionDropdown actionItem={actionItem} key={actionItem} />
          ) : (
            !actionItem?.hide && (
              <BulkActionButton actionItem={actionItem} key={actionItem} />
            )
          )
        )}
      </div>
    </div>
  );
};

export default SelectionCard;
