import { getCreatorConfig } from "features/Common/modules/CreatorData/utils/CreatorData.utils";
import { is_empty } from "utils/validations";
import { getAuthData, onUserLogout } from "utils/AuthUtil";

export function getSubdomain(): string | undefined {
  const authdata = getAuthData();
  // @ts-ignore
  if (is_empty(authdata?.sub_domain)) {
    onUserLogout();
    return;
  }
  // @ts-ignore
  return authdata?.sub_domain;
}

/**
 * check if custom domain linking is active for the creator, handles hostsite url accordingly
 * @returns string: creator hostsite url and render preview url
 */
export const getCreatorHostsiteURL = () => {
  const creatorConfig = getCreatorConfig();
  let url;
  let previewUrl;
  if (!is_empty(creatorConfig?.website_url)) {
    url = creatorConfig?.website_url;
    previewUrl = creatorConfig?.website_url.split(`https://`)[1]; // extract preview url without protocol
    return {
      url,
      previewUrl,
    };
  } else {
    const subDomain = getSubdomain();
    if (is_empty(subDomain)) return;
    previewUrl = `${subDomain}.${process.env.REACT_APP_PROJECT_DOMAIN}`;
    url = `https://${previewUrl}`;
  }
  return {
    url,
    previewUrl,
  };
};
