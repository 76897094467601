import {
  EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_ERROR_MODAL,
  EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";

export const usePayoutModal = () => {
  const payout = useSelector((state) => state.affiliateMarketing.payout);
  const dispatch = useDispatch();

  const handleShowAffiliatePayoutModal = (value) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_MODAL,
      payload: value,
    });
  };

  const handleShowAffiliatePayoutErrorModal = (value) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_ERROR_MODAL,
      payload: value,
    });
  };

  return {
    showAffiliatePayoutModal: payout?.showAffiliatePayoutModal,
    showAffiliatePayoutErrorModal: payout?.showAffiliatePayoutErrorModal,
    handleShowAffiliatePayoutModal,
    handleShowAffiliatePayoutErrorModal,
    payoutData: payout?.data,
    processing: payout?.processing,
  };
};
