export const affliatesLabel = {
  pageTitle: "Affiliate Marketing",
  pageDescription: "Manage all affiliates and their transactions",
};

export const form_data = {
  title: {
    key: "full_name",
    label: "Affiliate Name",
  },
  email: {
    key: "email",
    label: "Email",
  },
  phone_number: {
    key: "phone_number",
    label: "Phone",
  },
  country_code: {
    key: "country_codee",
    label: "Country code",
  },
  uuid: {
    key: "uuid",
    label: "Affiliate ID",
  },
  total_bookings: {
    key: "total_bookings",
    label: "Bookings",
    min: "min_total_bookings",
    max: "max_total_bookings",
  },
  total_commission: {
    key: "total_commission",
    label: "Total Commission",
    min: "min_total_commission",
    max: "max_total_commission",
  },
  total_sales_revenue: {
    key: "total_sales_revenue",
    min: "min_total_sales_revenue",
    max: "max_total_sales_revenue",
    label: "Sales Revenue",
  },
  pending_commission: {
    key: "pending_commission",
    label: "Pending Commission",
    min: "min_pending_commission",
    max: "max_pending_commission",
  },
  no_of_discount_codes: {
    key: "no_of_discount_codes",
    label: "Discount Codes",
  },
  discount_code: {
    key: "discount_code",
    label: "Discount Code",
  },
};
export const AFFLIATE_URL = "/affiliate/onboarding/login";

export const AFFLIATE_COMMISSION_STATUS = [
  { id: 1, value: 1, label: "Pending" },
  { id: 6, value: 6, label: "Approved" },
  { id: 7, value: 7, label: "Rejected" },
];
export const AFFLIATE_ENTITY_TYPE = {
  affiliate: {
    label: "affiliate",
  },
  affiliate_transactions: {
    label: "transaction",
  },
  managment: {
    label: "affiliate",
  },
  all_transactions: {
    label: "transaction",
  },
  codes: {
    label: "code",
  },
  transactions: {
    label: "transaction",
  },
};
export const AFFLIATE_TRANSCATIONS_STATUS = [
  { id: 1, value: 1, label: "Pending" },
  { id: 2, value: 2, label: "Approved" },
  { id: 3, value: 3, label: "Rejected" },
  { id: 4, value: 4, label: "Payout processed" },
  { id: 5, value: 5, label: "Payout failed" },
];
