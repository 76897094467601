import { pluralise } from "utils/Utils";
import { formatNumbeWithCommasString } from "@my-scoot/component-library-legacy";
import React from "react";

export const getSelectionText = ({
  selectAll,
  selectedRecords,
  entityName,
  totalRecords,
  currentPageFullSelection,
}) => {
  const selectedRecordsLength = selectedRecords?.length;
  if (selectAll) {
    return (
      <>
        <b>{formatNumbeWithCommasString(totalRecords)}</b>{" "}
        {`${pluralise(entityName, totalRecords)} `}across <b>all pages</b>{" "}
        selected.
      </>
    );
  } else if (currentPageFullSelection) {
    return (
      <>
        <b>{`${selectedRecordsLength}`}</b>{" "}
        {`${pluralise(
          entityName,
          selectedRecordsLength
        )} on this page selected.`}
      </>
    );
  } else {
    return `${selectedRecordsLength} ${pluralise(
      entityName,
      selectedRecordsLength
    )} selected.`;
  }
};
