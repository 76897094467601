import {
  EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_PAYOUT_STATUS,
  EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
  EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL,
  EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_ADD_AFFILIATE_FORM,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL,
  EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL,
  EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_MODAL,
  EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_ERROR_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD,
  EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING,
  EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
} from "./Affliates.actions";

const initialState = {
  payout: {
    data: {},
    showAffiliatePayoutModal: false,
    showAffiliatePayoutErrorModal: false,
    showPayoutListModal: false,
    payoutStatus: null,
  },

  processing: false,

  commissionFormInitialValues: {},

  affiliateSettings: {
    showAffiliateSettingsModal: false,
    formValues: {},
    processing: false,
  },

  addAffiliate: {
    showAddAffiliateModal: false,
    showAddAffliateSuccessModal: false,
    formValues: {},
  },

  discountCodes: {
    showModal: false,
    showDeleteModal: false,
    showSuccessModal: false,
    selectedRecord: {},
  },

  affiliateManagement: {
    showRejectionConfirmationModal: false,
    showApprovalSuccessModal: false,
    selectedAffiliateRecord: {},
  },

  affliateDetail: {
    uuid: "",
  },
};

const affiliateMarketing = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA: {
      return {
        ...state,
        payout: { ...state.payout, data: payload },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_MODAL: {
      return {
        ...state,
        payout: { ...state.payout, showAffiliatePayoutModal: payload },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES: {
      return {
        ...state,
        commissionFormInitialValues: payload,
      };
    }

    case EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_ERROR_MODAL: {
      return {
        ...state,
        payout: { ...state.payout, showAffiliatePayoutErrorModal: payload },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL: {
      return {
        ...state,
        payout: { ...state.payout, showPayoutListModal: payload },
      };
    }
    case EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL: {
      return {
        ...state,
        affliateDetail: payload,
      };
    }
    case EXLY_AFFILIATE_MARKETING__SET_PAYOUT_STATUS: {
      return {
        ...state,
        payout: { ...state.payout, payoutStatus: payload },
      };
    }
    case EXLY_AFFILIATE_MARKETING__SET_PROCESSING: {
      return {
        ...state,
        processing: payload,
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL: {
      return {
        ...state,
        affiliateSettings: {
          ...state.affiliateSettings,
          showAffiliateSettingsModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS: {
      return {
        ...state,
        affiliateSettings: {
          ...state.affiliateSettings,
          formValues: { ...state.affiliateSettings.formValues, ...payload },
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING: {
      return {
        ...state,
        affiliateSettings: {
          ...state.affiliateSettings,
          processing: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL: {
      return {
        ...state,
        addAffiliate: {
          ...state.addAffiliate,
          showAddAffiliateModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL: {
      return {
        ...state,
        addAffiliate: {
          ...state.addAffiliate,
          showAddAffliateSuccessModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_ADD_AFFILIATE_FORM: {
      return {
        ...state,
        addAffiliate: {
          ...state.addAffiliate,
          formValues: { ...state.addAffiliate.formValues, ...payload },
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL: {
      return {
        ...state,
        discountCodes: {
          ...state.discountCodes,
          showModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL: {
      return {
        ...state,
        discountCodes: {
          ...state.discountCodes,
          showDeleteModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL: {
      return {
        ...state,
        discountCodes: {
          ...state.discountCodes,
          showSuccessModal: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD: {
      return {
        ...state,
        discountCodes: {
          ...state.discountCodes,
          selectedRecord: payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL: {
      return {
        ...state,
        affiliateManagement: {
          ...state.affiliateManagement,
          ...payload,
        },
      };
    }

    case EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD: {
      return {
        ...state,
        affiliateManagement: {
          ...state.affiliateManagement,
          selectedAffiliateRecord: {
            ...state.affiliateManagement.selectedAffiliateRecord,
            ...payload,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default affiliateMarketing;
