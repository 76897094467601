import { appendUrlParams } from "utils/urlUtils";
import { IMAGE_SECTION_KEY_PATH } from "../../ExlyImage/constants/ExlyImage.constants";
import {
  isImageTypeValidForOptimization,
  uploadImageToExlyBucket,
} from "../../ExlyImage/utils/ExlyImage.utils";
import { IMAGE_CDN_FORMATS } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/constants/ExlyImage.constants";

export const removeExtensionFromFileName = (fileName) => {
  return fileName.replace(/\.[^/.]+$/, "");
};

export const handleFileUpload = async (file) => {
  if (typeof file === "string") return;
  try {
    let imageUrl = await uploadImageToExlyBucket({
      file: file,
      fileName: file.name,
      fileType: file.type,
      sectionName: IMAGE_SECTION_KEY_PATH.RTE,
    });
    /**
     * DEV - Appending format - auto to optimize the images
     * based on best format on browser since width is dynamic for rte images.
     */
    if (isImageTypeValidForOptimization(file.type))
      imageUrl = appendUrlParams(imageUrl, {
        format: IMAGE_CDN_FORMATS.AUTO,
      });
    return imageUrl;
  } catch (error) {
    console.error(error);
  }
};
