import React, { useCallback, useState } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import styles from "./bulkActionDropdown.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import { MenuItem, Popover } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const BulkActionDropdown = ({ actionItem }) => {
  const is_desktop = useDesktopMediaQuery();
  const { options = [], text = "" } = actionItem;
  const [anchorEl, setAnchorEl] = useState(null);
  const onOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Button
        color="primary"
        size={is_desktop ? "medium" : "thin"}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onOpen}
        fullWidth={is_desktop ? false : true}
      >
        {text}
        <KeyboardArrowDown className={`${styles.chevronDownIcon}`} />
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <>
          {options.map((option) => (
            <MenuItem
              className={styles.menuItems}
              key={option.value}
              onClick={option.onClick}
            >
              {option.label}
            </MenuItem>
          ))}
        </>
      </Popover>
    </ThemeProvider>
  );
};

export default BulkActionDropdown;
