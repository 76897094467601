import { EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA } from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch } from "react-redux";
import useCommissionPayout from "./usehandleCommissionPayout";
import { logError } from "utils/error";
import { missing_bank_details_error_code } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { usePayoutModal } from "features/AffiliateMarketing/modules/PayoutModal/utils/usePayoutModal";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";

const usePayout = () => {
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const handleCommissionPayout = useCommissionPayout();
  const {
    handleShowAffiliatePayoutErrorModal,
    handleShowAffiliatePayoutModal,
  } = usePayoutModal();

  const handlePayout = async ({ payoutType, transaction_uuids }) => {
    const { data, error } = await handleCommissionPayout({
      payoutType,
      transaction_uuids,
    });

    if (data) {
      handleShowAffiliatePayoutModal(true);
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
        payload: data,
      });
    } else if (error) {
      if (error?.body?.data?.error_status === missing_bank_details_error_code) {
        handleShowAffiliatePayoutErrorModal(true);
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
          payload: error.body.data,
        });
      } else {
        notify(
          error?.body?.message ?? SOMETHING_WENT_WRONG,
          notification_color_keys.error
        );
        logError({
          error: error,
          when: "handlePayout",
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateDetail/components/PayoutActions/index.jsx",
        });
      }
    }
  };

  return handlePayout;
};

export default usePayout;
