import React, { FC, Suspense } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { IExlyEmptyState } from "./ExlyEmptyState.interfaces";
import styles from "./ExlyEmptyState.module.css";
import classnames from "classnames";
import {
  isSeconadryCtaLearnMoreVariant,
  isSeconadryCtaPreviewImageVariant,
} from "./utils/ExlyEmptyState.utils";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import ExlyLoader from "ui/modules/ExlyLoader";
import { useToggleState } from "utils/hooks";
import LoadingState from "./components/LoadingState/LoadingState";

const VideoPlayer = React.lazy(
  () => import("./components/VideoPlayer/VideoPlayer")
);
const PreviewModal = React.lazy(
  () => import("./components/PreviewModal/PreviewModal")
);

const ExlyEmptyState: FC<IExlyEmptyState> = ({
  title,
  description,
  videoSrc,
  imgSrc,
  imgProps,
  alt,
  primaryCtaProps,
  secondaryCtaProps,
  className,
  playerProps,
  hideActionsOnMobile = true,
  isLoading,
}) => {
  const isDesktop = useIsDesktop();
  const hasLearnMoreCta = isSeconadryCtaLearnMoreVariant(
    secondaryCtaProps?.variant
  );
  const hasPreviewImage = isSeconadryCtaPreviewImageVariant(
    secondaryCtaProps?.variant
  );
  const showActions =
    (isDesktop || !hideActionsOnMobile) &&
    !!(primaryCtaProps || secondaryCtaProps);

  const [isPreviewModalOpen, openPreviewModal, closePreviewModal] =
    useToggleState(false);

  const handleLeanMore = () => {
    if (!secondaryCtaProps) return;

    const { learnMoreHref } = secondaryCtaProps;

    if (learnMoreHref) window.open(learnMoreHref, "_blank");
  };

  const handleSecondaryCtaClick = () => {
    if (hasPreviewImage) {
      openPreviewModal();
    } else if (hasLearnMoreCta) {
      handleLeanMore();
    } else if (secondaryCtaProps && secondaryCtaProps.onClick) {
      secondaryCtaProps.onClick();
    }
  };

  if (isLoading) return <LoadingState showActions={showActions} />;

  return (
    <Suspense fallback={ExlyLoader}>
      <div className={classnames(styles.root, className)}>
        {imgSrc ? (
          <img
            className={styles.imgRoot}
            src={imgSrc}
            alt={alt}
            {...imgProps}
          />
        ) : null}

        <h2>{title}</h2>

        <p>{description}</p>

        {videoSrc ? (
          <VideoPlayer videoSrc={videoSrc} playerProps={playerProps} />
        ) : null}

        {showActions ? (
          <div className={styles.actions}>
            {primaryCtaProps ? (
              <Button onClick={primaryCtaProps.onClick}>
                {primaryCtaProps.title}
              </Button>
            ) : null}

            {secondaryCtaProps ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSecondaryCtaClick}
              >
                {hasLearnMoreCta ? "Learn More" : secondaryCtaProps.title}
              </Button>
            ) : null}
          </div>
        ) : null}

        {hasPreviewImage && secondaryCtaProps?.previewImgSrc ? (
          <PreviewModal
            open={isPreviewModalOpen}
            imgSrc={secondaryCtaProps?.previewImgSrc}
            onClose={closePreviewModal}
          />
        ) : null}
      </div>
    </Suspense>
  );
};

export default ExlyEmptyState;
