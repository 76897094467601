import React, { FC } from "react";
import { Button } from "@my-scoot/component-library-legacy";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { ButtonProps } from "@material-ui/core";

const LearnMoreCta: FC<{ href: string; className?: string } & ButtonProps> = ({
  href,
  className,
  ...restProps
}) => {
  const isDesktop = useIsDesktop();

  const handleClick = () => {
    if (href) window.open(href, "_blank");
  };

  return (
    <Button
      color="tertiary"
      size={isDesktop ? "medium" : "thin"}
      variant="outlined"
      startIcon={<HelpIcon />}
      onClick={handleClick}
      className={className}
      {...restProps}
    >
      Learn more
    </Button>
  );
};

export default LearnMoreCta;
