import {
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "./Affliates.urlPaths";
import { is_empty } from "utils/validations";
import { getSessionStorageItem } from "utils/getterAndSetters";
import { extractIdFromURL } from "../utils/AffiliateMarketing.utils";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";

export const FETCH_NEW_AFFILIATE_REQUEST = "affiliates/profile/requests/list";
export const FETCH_AFFLIATE_TRANSACTIONS_LIST = "affiliates/transactions/list";
export const FETCH_AFFLIATE_PAYOUT_LIST = "affiliates/transactions/summary";
export const FETCH_AFFLIATE_DISCOUNT_LIST = "affiliates/discounts/list";
export const FETCH_AFFLIATE_OFFERINGS =
  "affiliates/transactions/offering/report";
export const FETCH_SPECIFIC_OFFERINGS_LIST =
  "affiliates/profile/assigned/listings";
export const DELETE_DISCOUNT_CODE_API = "affiliates/discounts/delete";
export const VALIDATE_COMMISSION_PAYOUT =
  "affiliates/transactions/validate/commission/payouts";
export const INITIATE_COMMISSION_PAYMENT_API =
  "affiliates/transactions/initiate/commission/payment";
export const REVIEW_TRANSACTIONS_FOR_PAYOUT_API =
  "affiliates/transactions/review/commission/payouts";
export const COMMISSION_PAYOUT_LIST_API =
  "affiliates/transactions/commission/payout/list";
export const GET_PAYOUT_STATUS_API =
  "affiliates/transactions/commission/order/status";
export const RETRY_PAYMENT_API =
  "affiliates/transactions/retry/commission/payment";
export const RETRY_PAYOUT_API = "affiliates/transactions/retry/payout";
export const GET_TRANSACTION_INVOICE_URL_API =
  "affiliates/transactions/view/receipt";

// affiliate settings
export const FETCH_AFFILIATE_SETTINGS_API = "affiliates/config/get";
export const UPDATE_AFFILIATE_SETTINGS_API = "affiliates/config/update";

// affiliate profile
export const ADD_AFFLIATE_API = "affiliates/profile/create";
export const GET_OFFERING_LINK_API = "affiliates/profile/get/listing/link";
export const SEND_REMAINDER = "affiliates/profile/send/bank/account/reminder";
export const FETCH_AFFLIATE_PROFILE = "affiliates/profile/details";
export const UPDATE_AFFLIATE_PROFILE_API = "affiliates/profile/update";
export const FETCH_COMMISSION_STATS = "affiliates/profile/commission/summary";
export const FETCH_AFFILIATE_MANAGEMENT = "affiliates/profile/list";
export const FETCH_AFFLIATES_STATS = "affiliates/profile/statistics";

// affiliate details - transactions
export const AFFILIATE_TRANSACTION_UPDATE_API =
  "affiliates/transactions/update";

export const management_list_apis = {
  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.list_affliate_management
  ]]: {
    api_end_point: FETCH_AFFILIATE_MANAGEMENT,
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.new_user_req]]: {
    api_end_point: FETCH_NEW_AFFILIATE_REQUEST,
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.affliate_detail]]:
    () => {
      const affiliate_uuid = extractIdFromURL();
      return {
        api_end_point: FETCH_AFFLIATE_TRANSACTIONS_LIST,
        queries: {
          affiliate_uuid,
        },
      };
    },
  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.offerings_wise_report
  ]]: {
    api_end_point: FETCH_NEW_AFFILIATE_REQUEST,
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.payout_summary]]:
    () => {
      return {
        api_end_point: FETCH_AFFLIATE_PAYOUT_LIST,
        queries: {
          affiliate_uuid: extractIdFromURL(),
        },
      };
    },

  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.review_selection_list_modal
  ]]: () => {
    const request_id = getSessionStorageItem(
      SESSION_STORAGE_KEYS.AFFILIATE_PAYOUT_REQUEST_ID
    );

    if (is_empty(request_id)) return;
    return {
      api_end_point: COMMISSION_PAYOUT_LIST_API,
      queries: {
        request_id,
      },
    };
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.discount_codes]]:
    () => {
      const affiliate_uuid = extractIdFromURL();
      return {
        api_end_point: FETCH_AFFLIATE_DISCOUNT_LIST,
        queries: {
          affiliate_uuid,
        },
      };
    },
  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.offerings_wise_report
  ]]: () => {
    const affiliate_uuid = extractIdFromURL();
    return {
      api_end_point: FETCH_AFFLIATE_OFFERINGS,
      queries: {
        affiliate_uuid,
      },
    };
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.list_all_transcation
  ]]: {
    api_end_point: FETCH_AFFLIATE_TRANSACTIONS_LIST,
  },
  [AFFILIATE_MARKETING_ROUTE_IDS[
    AFFILIATE_MARKETING_URL_KEYS.list_all_payout_summary
  ]]: {
    api_end_point: FETCH_AFFLIATE_PAYOUT_LIST,
  },
};
