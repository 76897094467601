import {
  isLoomVideoUrl as _isLoomVideoUrl,
  getLoomVideoEmbedUrlFromShareUrl as _getLoomVideoEmbedUrlFromShareUrl,
  isVimeoVideoLink as _isVimeoVideoLink,
  // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/url";
import type {
  isLoomVideoUrl as isLoomVideoUrlType,
  getLoomVideoEmbedUrlFromShareUrl as getLoomVideoEmbedUrlFromShareUrlType,
  isVimeoVideoLink as isVimeoVideoLinkType,
} from "@my-scoot/exly-react-component-lib/build/types/utils/common/url";

export const isVimeoVideoLink: typeof isVimeoVideoLinkType = _isVimeoVideoLink;

export const isLoomVideoUrl: typeof isLoomVideoUrlType = _isLoomVideoUrl;

export const getLoomVideoEmbedUrlFromShareUrl: typeof getLoomVideoEmbedUrlFromShareUrlType =
  _getLoomVideoEmbedUrlFromShareUrl;
