import React, { FC } from "react";
import HelpIcon from "@material-ui/icons/Help";
import classnames from "classnames";
import styles from "./LearnMoreStrip.module.css";

const LearnMoreStrip: FC<{
  href: string;
  about: string;
  className?: string;
}> = ({ href, about, className }) => {
  return (
    <div className={classnames(styles.root, className)}>
      <HelpIcon />{" "}
      <a href={href} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>{" "}
      {about}
    </div>
  );
};

export default LearnMoreStrip;
