export const EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA =
  "@EXLY_AFFILIATE_MARKETING/SET_PAYOUT_DATA";
export const EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_MODAL =
  "@EXLY_AFFILIATE_MARKETING/SHOW_AFFILIATE_PAYOUT_MODAL";
export const EXLY_AFFILIATE_MARKETING__SHOW_AFFILIATE_PAYOUT_ERROR_MODAL =
  "@EXLY_AFFILIATE_MARKETING/SHOW_AFFILIATE_PAYOUT_ERROR_MODAL";
export const EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL =
  "@EXLY_AFFILIATE_MARKETING/SHOW_PAYOUT_LIST_MODAL";
export const EXLY_AFFILIATE_MARKETING__SET_PAYOUT_STATUS =
  "@EXLY_AFFILIATE_MARKETING/SET_PAYOUT_STATUS";
export const EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL =
  "@EXLY_AFFILIATE_MARKETING/SET_AFFLIATE_DETAIL";
export const EXLY_AFFILIATE_MARKETING__SET_PROCESSING =
  "@EXLY_AFFILIATE_MARKETING/SET_PROCESSING";

// affiliate settings
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_AFFILIATE_SETTINGS_MODAL";
export const EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS =
  "@EXLYAFFILIATE_MARKETING/FETCH_AFFILIATE_SETTINGS";
export const EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS =
  "@EXLYAFFILIATE_MARKETING/SET_AFFILIATE_SETTINGS";
export const EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES =
  "@EXLYAFFILIATE_MARKETING/SET_COMMISSION_INITIAL_VALUES";

// add affiliate
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_ADD_AFFILIATE_MODAL";
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL";
export const EXLY_AFFILIATE_MARKETING__SET_ADD_AFFILIATE_FORM =
  "@EXLYAFFILIATE_MARKETING/SET_ADD_AFFILIATE_FORM";
export const EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING =
  "@EXLYAFFILIATE_MARKETING/SET_AFFILIATE_SETTINGS_PROCESSING";

//discount codes
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_DISCOUNT_CODES_MODAL";
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_DISCOUNT_CODES_DELETE_MODAL";
export const EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD =
  "@EXLYAFFILIATE_MARKETING/SET_SELECTED_DISCOUNT_RECORD";
export const EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL =
  "@EXLYAFFILIATE_MARKETING/SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL";

//affiliate management
export const EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SHOW_MODAL =
  "@EXLYAFFILIATE_MARKETING/AFFILIATE_MANAGEMENT/SHOW_MODAL";
export const EXLY_AFFILIATE_MARKETING__AFFILIATE_MANAGEMENT__SET_SELECTED_AFFILIATE_RECORD =
  "@EXLYAFFILIATE_MARKETING/AFFILIATE_MANAGEMENT/SET_SELECTED_AFFILIATE_RECORD";
