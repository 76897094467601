import { LOCAL_STORAGE_KEYS } from "../constants/LocalStorage.constants";

export const setLocalStorageItem = (key, value) => {
  if (Object.values(LOCAL_STORAGE_KEYS).includes(key)) {
    localStorage.setItem(key, value);
  }
};

export const getLocalStorageItem = (key) => {
  if (Object.values(LOCAL_STORAGE_KEYS).includes(key)) {
    return localStorage.getItem(key) ?? "";
  }
  return "";
};

export const removeLocalStorageItem = (key) => {
  if (Object.values(LOCAL_STORAGE_KEYS).includes(key)) {
    localStorage.removeItem(key);
  }
};
