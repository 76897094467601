export const form_data = {
  customer_name: {
    key: "customer_name",
    label: "Customer name",
  },
  customer_email: {
    key: "customer_email",
    label: "Email",
  },
  customer_phone: {
    key: "customer_phone",
    filterKey: "guest__phone_number",
    label: "Phone",
  },
  total_bookings: {
    key: "total_bookings",
    label: "Total Bookings",
  },
  session_name: {
    key: "session_name",
    label: "Offering Name",
    filterKey: "listing__title",
  },
  session_type: {
    key: "session_type",
    filterKey: "listing__type",
    label: "Offering Type",
  },
  listing_type: { key: "listing_type", label: "Offering Type" },
  payment_type: { key: "payment_type", label: "Payment Plan Type" },
  price: {
    key: "price",
    label: "Price Per Spot",
  },
  transaction_amount: {
    key: "transaction_amount",
    min: "min_transaction_amount",
    max: "max_transaction_amount",
    label: "Transaction Amount",
  },
  discount_code: {
    key: "discount_code",
    label: "Discount Code",
  },
  feature_discount_code: {
    key: "feature_discount_code",
    label: "Limited Time Offer",
  },
  discount_amount: {
    key: "discount_amount",
    label: "Discount Amount",
  },
  transaction_id: {
    key: "transaction_id",
    label: "Transaction ID",
  },
  created_at: {
    key: "created_at",
    label: "Transaction Date",
  },
  guest_count: {
    key: "guest_count",
    label: "No. of Units",
  },
  is_international: {
    key: "is_international",
    label: "International Transaction",
  },
  receipt_url: {
    key: "receipt_url",
    label: "Customer Invoice",
  },
  commission_amount: {
    key: "commission_amount",
    label: "Commission Amount",
  },
  commission_payout_date: {
    key: "commission_payout_date",
    label: "Commission Payout Date",
  },
  name: {
    key: "guest_name",
    label: "Name",
  },
  affiliate_name: {
    key: "affiliate_name",
    label: "Affiliate name",
  },
  email: {
    key: "guest__email",
    label: "Email",
  },
  start_date: {
    key: "start_date",
    label: "From",
  },
  end_date: {
    key: "end_date",
    label: "To",
  },
  min_price: {
    key: "min_price",
    label: "Minimum Price",
  },
  max_price: {
    key: "max_price",
    label: "Maximum Price",
  },
  min_commission_amount: {
    key: "min_commission_amount",
    label: "Minimum commission amount",
  },
  max_commission_amount: {
    key: "max_commission_amount",
    label: "Maximum commission amount",
  },
  commission_payout_status: {
    key: "commission_payout_status",
    label: "Commission Payout Status",
  },
  type: {
    key: "type",
    label: "Offering Type",
  },
  payment_plan_type: {
    key: "payment_plan_type",
    label: "payment_plan_type",
  },
  staff: {
    key: "staff",
    label: "Staff",
  },
};

export const affiliates_detail_tab = {
  earning_and_activity: {
    label: "Earnings & Activity",
    value: 4,
    subTabs: {
      all_transactions: {
        value: "all_transactions",
      },
      offering_wise_report: {
        value: "offering_wise_report",
      },
      payout_summary: {
        value: "payout_summary",
      },
    },
  },
  discount_codes: {
    label: "Discount Codes",
    value: 1,
  },
};

export const DETAILS_TAB = {
  basic_details: {
    label: "Basic Details & Commission",
    key: "basic_details",
    value: "basic_details",
  },
  bank_details: {
    label: "Bank Details",
    key: "bank_details",
    value: "bank_details",
  },
};

export const COMISSION_PAYOUT_TYPE = [
  { label: "Pay all approved commissions", value: 1 },
  { label: "Pay all approved & pending commissions", value: 2 },
];
export const COMMISSION_STATUS = {
  failed: {
    value: 3,
    name: "failed",
    displayName: "Failed",
  },
  processed: {
    value: 5,
    name: "processed",
    displayName: "Processed",
  },
  exly_initiated: {
    value: 2,
    name: "exly_initiated",
    displayName: "Exly Initiated",
  },
  bank_details: {
    value: 2,
    name: "bank_details",
    displayName: "Miising Bank details",
  },
};
export const COMISSION_PAYOUT_TYPE_IDS = {
  all: 1,
  approved_only: 2,
  approved_and_pending: 3,
};

export const COMISSION_PAYOUT_OPTIONS = {
  [COMISSION_PAYOUT_TYPE_IDS.approved_only]: {
    label: "Pay all approved commissions",
    value: COMISSION_PAYOUT_TYPE_IDS.approved_only,
  },
  [COMISSION_PAYOUT_TYPE_IDS.approved_and_pending]: {
    label: "Pay all approved & pending commissions",
    value: COMISSION_PAYOUT_TYPE_IDS.approved_and_pending,
  },
};
