export const BULK_ACTION_TABLES = {
  TRANSACTIONS: 1,
  NON_SUBSCRIPTION_BOOKINGS: 2,
  LEADS: 3,
  CUSTOMERS: 4,
  MY_BOOKINGS: 5,
  SUBSCRIPTION_BOOKINGS: 6,
  AFFLIATES_REQUESTS: 7,
  AFFLIATES_ALL_TRANSACTIONS: 8,
  AUTOMATED_EXAM_RESULTS: 9,
};

export const BULK_ACTION_TYPES = {
  ASSIGN_TAGS: 1,
  BROADCAST: 2,
  REFUNDS: 3, // @DEV this is not supported or implemented now,will be needed later
  AFFLIATES_REQUESTS_STATUS_UPDATE: 4, // this is for affiliate status
  COMMISSSION_STATUS: 5, // update affiliate  transaction commission status
  PUBLISH_AUTOMATED_EXAM_RESULTS: 6,
};

export const INPUT_TYPE = {
  dropdown: "dropdown",
};

export const requestIdParam = "request_id";
