export const country_codes = {
  AF: { code: "AF", name: "Afghanistan", dial_code: "+93" },
  AL: { code: "AL", name: "Albania", dial_code: "+355" },
  DZ: { code: "DZ", name: "Algeria", dial_code: "+213" },
  AS: { code: "AS", name: "AmericanSamoa", dial_code: "+1 684" },
  AD: { code: "AD", name: "Andorra", dial_code: "+376" },
  AO: { code: "AO", name: "Angola", dial_code: "+244" },
  AI: { code: "AI", name: "Anguilla", dial_code: "+1 264" },
  AG: { code: "AG", name: "Antigua and Barbuda", dial_code: "+1268" },
  AR: { code: "AR", name: "Argentina", dial_code: "+54" },
  AM: { code: "AM", name: "Armenia", dial_code: "+374" },
  AW: { code: "AW", name: "Aruba", dial_code: "+297" },
  AU: { code: "AU", name: "Australia", dial_code: "+61" },
  AT: { code: "AT", name: "Austria", dial_code: "+43" },
  AZ: { code: "AZ", name: "Azerbaijan", dial_code: "+994" },
  BS: { code: "BS", name: "Bahamas", dial_code: "+1 242" },
  BH: { code: "BH", name: "Bahrain", dial_code: "+973" },
  BD: { code: "BD", name: "Bangladesh", dial_code: "+880" },
  BB: { code: "BB", name: "Barbados", dial_code: "+1 246" },
  BY: { code: "BY", name: "Belarus", dial_code: "+375" },
  BE: { code: "BE", name: "Belgium", dial_code: "+32" },
  BZ: { code: "BZ", name: "Belize", dial_code: "+501" },
  BJ: { code: "BJ", name: "Benin", dial_code: "+229" },
  BM: { code: "BM", name: "Bermuda", dial_code: "+1 441" },
  BT: { code: "BT", name: "Bhutan", dial_code: "+975" },
  BO: {
    code: "BO",
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
  },
  BA: { code: "BA", name: "Bosnia and Herzegovina", dial_code: "+387" },
  BW: { code: "BW", name: "Botswana", dial_code: "+267" },
  BR: { code: "BR", name: "Brazil", dial_code: "+55" },
  IO: { code: "IO", name: "British Indian Ocean Territory", dial_code: "+246" },
  BN: { code: "BN", name: "Brunei Darussalam", dial_code: "+673" },
  BG: { code: "BG", name: "Bulgaria", dial_code: "+359" },
  BF: { code: "BF", name: "Burkina Faso", dial_code: "+226" },
  BI: { code: "BI", name: "Burundi", dial_code: "+257" },
  KH: { code: "KH", name: "Cambodia", dial_code: "+855" },
  CM: { code: "CM", name: "Cameroon", dial_code: "+237" },
  CA: { code: "CA", name: "Canada", dial_code: "+1" },
  CV: { code: "CV", name: "Cape Verde", dial_code: "+238" },
  KY: { code: "KY", name: "Cayman Islands", dial_code: "+ 345" },
  CF: { code: "CF", name: "Central African Republic", dial_code: "+236" },
  TD: { code: "TD", name: "Chad", dial_code: "+235" },
  CL: { code: "CL", name: "Chile", dial_code: "+56" },
  CN: { code: "CN", name: "China", dial_code: "+86" },
  CX: { code: "CX", name: "Christmas Island", dial_code: "+61" },
  CC: { code: "CC", name: "Cocos (Keeling) Islands", dial_code: "+61" },
  CO: { code: "CO", name: "Colombia", dial_code: "+57" },
  KM: { code: "KM", name: "Comoros", dial_code: "+269" },
  CG: { code: "CG", name: "Congo", dial_code: "+242" },
  CD: {
    code: "CD",
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
  },
  CK: { code: "CK", name: "Cook Islands", dial_code: "+682" },
  CR: { code: "CR", name: "Costa Rica", dial_code: "+506" },
  CI: { code: "CI", name: "Cote 'Ivoire", dial_code: "+225" },
  HR: { code: "HR", name: "Croatia", dial_code: "+385" },
  CU: { code: "CU", name: "Cuba", dial_code: "+53" },
  CY: { code: "CY", name: "Cyprus", dial_code: "+537" },
  CZ: { code: "CZ", name: "Czech Republic", dial_code: "+420" },
  DK: { code: "DK", name: "Denmark", dial_code: "+45" },
  DJ: { code: "DJ", name: "Djibouti", dial_code: "+253" },
  DM: { code: "DM", name: "Dominica", dial_code: "+1 767" },
  DO: { code: "DO", name: "Dominican Republic", dial_code: "+1 849" },
  EC: { code: "EC", name: "Ecuador", dial_code: "+593" },
  EG: { code: "EG", name: "Egypt", dial_code: "+20" },
  SV: { code: "SV", name: "El Salvador", dial_code: "+503" },
  GQ: { code: "GQ", name: "Equatorial Guinea", dial_code: "+240" },
  ER: { code: "ER", name: "Eritrea", dial_code: "+291" },
  EE: { code: "EE", name: "Estonia", dial_code: "+372" },
  ET: { code: "ET", name: "Ethiopia", dial_code: "+251" },
  FK: { code: "FK", name: "Falkland Islands (Malvinas)", dial_code: "+500" },
  FO: { code: "FO", name: "Faroe Islands", dial_code: "+298" },
  FJ: { code: "FJ", name: "Fiji", dial_code: "+679" },
  FI: { code: "FI", name: "Finland", dial_code: "+358" },
  FR: { code: "FR", name: "France", dial_code: "+33" },
  GF: { code: "GF", name: "French Guiana", dial_code: "+594" },
  PF: { code: "PF", name: "French Polynesia", dial_code: "+689" },
  GA: { code: "GA", name: "Gabon", dial_code: "+241" },
  GM: { code: "GM", name: "Gambia", dial_code: "+220" },
  GE: { code: "GE", name: "Georgia", dial_code: "+995" },
  DE: { code: "DE", name: "Germany", dial_code: "+49" },
  GH: { code: "GH", name: "Ghana", dial_code: "+233" },
  GI: { code: "GI", name: "Gibraltar", dial_code: "+350" },
  GR: { code: "GR", name: "Greece", dial_code: "+30" },
  GL: { code: "GL", name: "Greenland", dial_code: "+299" },
  GD: { code: "GD", name: "Grenada", dial_code: "+1 473" },
  GP: { code: "GP", name: "Guadeloupe", dial_code: "+590" },
  GU: { code: "GU", name: "Guam", dial_code: "+1 671" },
  GT: { code: "GT", name: "Guatemala", dial_code: "+502" },
  GG: { code: "GG", name: "Guernsey", dial_code: "+44" },
  GN: { code: "GN", name: "Guinea", dial_code: "+224" },
  GW: { code: "GW", name: "Guinea-Bissau", dial_code: "+245" },
  GY: { code: "GY", name: "Guyana", dial_code: "+595" },
  HT: { code: "HT", name: "Haiti", dial_code: "+509" },
  VA: { code: "VA", name: "Holy See (Vatican City State)", dial_code: "+379" },
  HN: { code: "HN", name: "Honduras", dial_code: "+504" },
  HK: { code: "HK", name: "Hong Kong", dial_code: "+852" },
  HU: { code: "HU", name: "Hungary", dial_code: "+36" },
  IS: { code: "IS", name: "Iceland", dial_code: "+354" },
  IN: { code: "IN", name: "India", dial_code: "+91" },
  ID: { code: "ID", name: "Indonesia", dial_code: "+62" },
  IR: { code: "IR", name: "Iran, Islamic Republic of", dial_code: "+98" },
  IQ: { code: "IQ", name: "Iraq", dial_code: "+964" },
  IE: { code: "IE", name: "Ireland", dial_code: "+353" },
  IM: { code: "IM", name: "Isle of Man", dial_code: "+44" },
  IL: { code: "IL", name: "Israel", dial_code: "+972" },
  IT: { code: "IT", name: "Italy", dial_code: "+39" },
  JM: { code: "JM", name: "Jamaica", dial_code: "+1 876" },
  JP: { code: "JP", name: "Japan", dial_code: "+81" },
  JE: { code: "JE", name: "Jersey", dial_code: "+44" },
  JO: { code: "JO", name: "Jordan", dial_code: "+962" },
  KZ: { code: "KZ", name: "Kazakhstan", dial_code: "+7 7" },
  KE: { code: "KE", name: "Kenya", dial_code: "+254" },
  KI: { code: "KI", name: "Kiribati", dial_code: "+686" },
  KP: {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
  },
  KR: { code: "KR", name: "Korea, Republic of", dial_code: "+82" },
  KW: { code: "KW", name: "Kuwait", dial_code: "+965" },
  KG: { code: "KG", name: "Kyrgyzstan", dial_code: "+996" },
  LA: {
    code: "LA",
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
  },
  LV: { code: "LV", name: "Latvia", dial_code: "+371" },
  LB: { code: "LB", name: "Lebanon", dial_code: "+961" },
  LS: { code: "LS", name: "Lesotho", dial_code: "+266" },
  LR: { code: "LR", name: "Liberia", dial_code: "+231" },
  LY: { code: "LY", name: "Libyan Arab Jamahiriya", dial_code: "+218" },
  LI: { code: "LI", name: "Liechtenstein", dial_code: "+423" },
  LT: { code: "LT", name: "Lithuania", dial_code: "+370" },
  LU: { code: "LU", name: "Luxembourg", dial_code: "+352" },
  MO: { code: "MO", name: "Macao", dial_code: "+853" },
  MK: {
    code: "MK",
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
  },
  MG: { code: "MG", name: "Madagascar", dial_code: "+261" },
  MW: { code: "MW", name: "Malawi", dial_code: "+265" },
  MY: { code: "MY", name: "Malaysia", dial_code: "+60" },
  MV: { code: "MV", name: "Maldives", dial_code: "+960" },
  ML: { code: "ML", name: "Mali", dial_code: "+223" },
  MT: { code: "MT", name: "Malta", dial_code: "+356" },
  MH: { code: "MH", name: "Marshall Islands", dial_code: "+692" },
  MQ: { code: "MQ", name: "Martinique", dial_code: "+596" },
  MR: { code: "MR", name: "Mauritania", dial_code: "+222" },
  MU: { code: "MU", name: "Mauritius", dial_code: "+230" },
  YT: { code: "YT", name: "Mayotte", dial_code: "+262" },
  MX: { code: "MX", name: "Mexico", dial_code: "+52" },
  FM: {
    code: "FM",
    name: "Micronesia, Federated States of",
    dial_code: "+691",
  },
  MD: { code: "MD", name: "Moldova, Republic of", dial_code: "+373" },
  MC: { code: "MC", name: "Monaco", dial_code: "+377" },
  MN: { code: "MN", name: "Mongolia", dial_code: "+976" },
  ME: { code: "ME", name: "Montenegro", dial_code: "+382" },
  MS: { code: "MS", name: "Montserrat", dial_code: "+1664" },
  MA: { code: "MA", name: "Morocco", dial_code: "+212" },
  MZ: { code: "MZ", name: "Mozambique", dial_code: "+258" },
  MM: { code: "MM", name: "Myanmar", dial_code: "+95" },
  NA: { code: "NA", name: "Namibia", dial_code: "+264" },
  NR: { code: "NR", name: "Nauru", dial_code: "+674" },
  NP: { code: "NP", name: "Nepal", dial_code: "+977" },
  NL: { code: "NL", name: "Netherlands", dial_code: "+31" },
  AN: { code: "AN", name: "Netherlands Antilles", dial_code: "+599" },
  NC: { code: "NC", name: "New Caledonia", dial_code: "+687" },
  NZ: { code: "NZ", name: "New Zealand", dial_code: "+64" },
  NI: { code: "NI", name: "Nicaragua", dial_code: "+505" },
  NE: { code: "NE", name: "Niger", dial_code: "+227" },
  NG: { code: "NG", name: "Nigeria", dial_code: "+234" },
  NU: { code: "NU", name: "Niue", dial_code: "+683" },
  NF: { code: "NF", name: "Norfolk Island", dial_code: "+672" },
  MP: { code: "MP", name: "Northern Mariana Islands", dial_code: "+1 670" },
  NO: { code: "NO", name: "Norway", dial_code: "+47" },
  OM: { code: "OM", name: "Oman", dial_code: "+968" },
  PK: { code: "PK", name: "Pakistan", dial_code: "+92" },
  PW: { code: "PW", name: "Palau", dial_code: "+680" },
  PS: {
    code: "PS",
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
  },
  PA: { code: "PA", name: "Panama", dial_code: "+507" },
  PG: { code: "PG", name: "Papua New Guinea", dial_code: "+675" },
  PY: { code: "PY", name: "Paraguay", dial_code: "+595" },
  PE: { code: "PE", name: "Peru", dial_code: "+51" },
  PH: { code: "PH", name: "Philippines", dial_code: "+63" },
  PN: { code: "PN", name: "Pitcairn", dial_code: "+872" },
  PL: { code: "PL", name: "Poland", dial_code: "+48" },
  PT: { code: "PT", name: "Portugal", dial_code: "+351" },
  PR: { code: "PR", name: "Puerto Rico", dial_code: "+1 939" },
  QA: { code: "QA", name: "Qatar", dial_code: "+974" },
  RO: { code: "RO", name: "Romania", dial_code: "+40" },
  RU: { code: "RU", name: "Russia", dial_code: "+7" },
  RW: { code: "RW", name: "Rwanda", dial_code: "+250" },
  RE: { code: "RE", name: "R\\u00e9union", dial_code: "+262" },
  BL: { code: "BL", name: "Saint Barth\\u00e9lemy", dial_code: "+590" },
  SH: {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
  },
  KN: { code: "KN", name: "Saint Kitts and Nevis", dial_code: "+1 869" },
  LC: { code: "LC", name: "Saint Lucia", dial_code: "+1 758" },
  MF: { code: "MF", name: "Saint Martin", dial_code: "+590" },
  PM: { code: "PM", name: "Saint Pierre and Miquelon", dial_code: "+508" },
  VC: {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
  },
  WS: { code: "WS", name: "Samoa", dial_code: "+685" },
  SM: { code: "SM", name: "San Marino", dial_code: "+378" },
  ST: { code: "ST", name: "Sao Tome and Principe", dial_code: "+239" },
  SA: { code: "SA", name: "Saudi Arabia", dial_code: "+966" },
  SN: { code: "SN", name: "Senegal", dial_code: "+221" },
  RS: { code: "RS", name: "Serbia", dial_code: "+381" },
  SC: { code: "SC", name: "Seychelles", dial_code: "+248" },
  SL: { code: "SL", name: "Sierra Leone", dial_code: "+232" },
  SG: { code: "SG", name: "Singapore", dial_code: "+65" },
  SK: { code: "SK", name: "Slovakia", dial_code: "+421" },
  SI: { code: "SI", name: "Slovenia", dial_code: "+386" },
  SB: { code: "SB", name: "Solomon Islands", dial_code: "+677" },
  SO: { code: "SO", name: "Somalia", dial_code: "+252" },
  ZA: { code: "ZA", name: "South Africa", dial_code: "+27" },
  GS: {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
  },
  ES: { code: "ES", name: "Spain", dial_code: "+34" },
  LK: { code: "LK", name: "Sri Lanka", dial_code: "+94" },
  SD: { code: "SD", name: "Sudan", dial_code: "+249" },
  SR: { code: "SR", name: "Suriname", dial_code: "+597" },
  SJ: { code: "SJ", name: "Svalbard and Jan Mayen", dial_code: "+47" },
  SZ: { code: "SZ", name: "Swaziland", dial_code: "+268" },
  SE: { code: "SE", name: "Sweden", dial_code: "+46" },
  CH: { code: "CH", name: "Switzerland", dial_code: "+41" },
  SY: { code: "SY", name: "Syrian Arab Republic", dial_code: "+963" },
  TW: { code: "TW", name: "Taiwan, Province of China", dial_code: "+886" },
  TJ: { code: "TJ", name: "Tajikistan", dial_code: "+992" },
  TZ: { code: "TZ", name: "Tanzania, United Republic of", dial_code: "+255" },
  TH: { code: "TH", name: "Thailand", dial_code: "+66" },
  TL: { code: "TL", name: "Timor-Leste", dial_code: "+670" },
  TG: { code: "TG", name: "Togo", dial_code: "+228" },
  TK: { code: "TK", name: "Tokelau", dial_code: "+690" },
  TO: { code: "TO", name: "Tonga", dial_code: "+676" },
  TT: { code: "TT", name: "Trinidad and Tobago", dial_code: "+1 868" },
  TN: { code: "TN", name: "Tunisia", dial_code: "+216" },
  TR: { code: "TR", name: "Turkey", dial_code: "+90" },
  TM: { code: "TM", name: "Turkmenistan", dial_code: "+993" },
  TC: { code: "TC", name: "Turks and Caicos Islands", dial_code: "+1 649" },
  TV: { code: "TV", name: "Tuvalu", dial_code: "+688" },
  UG: { code: "UG", name: "Uganda", dial_code: "+256" },
  UA: { code: "UA", name: "Ukraine", dial_code: "+380" },
  AE: { code: "AE", name: "United Arab Emirates", dial_code: "+971" },
  GB: { code: "GB", name: "United Kingdom", dial_code: "+44" },
  US: { code: "US", name: "United States", dial_code: "+1" },
  UY: { code: "UY", name: "Uruguay", dial_code: "+598" },
  UZ: { code: "UZ", name: "Uzbekistan", dial_code: "+998" },
  VU: { code: "VU", name: "Vanuatu", dial_code: "+678" },
  VE: {
    code: "VE",
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
  },
  VN: { code: "VN", name: "Viet Nam", dial_code: "+84" },
  VG: { code: "VG", name: "Virgin Islands, British", dial_code: "+1 284" },
  VI: { code: "VI", name: "Virgin Islands, U.S.", dial_code: "+1 340" },
  WF: { code: "WF", name: "Wallis and Futuna", dial_code: "+681" },
  YE: { code: "YE", name: "Yemen", dial_code: "+967" },
  ZM: { code: "ZM", name: "Zambia", dial_code: "+260" },
  ZW: { code: "ZW", name: "Zimbabwe", dial_code: "+263" },
};
