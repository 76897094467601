import { dataProvider } from "data";
import { FETCH_CREATOR_CONFIG_API } from "../CreatorData.constants";
import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { is_empty } from "utils/validations";
import { logError } from "utils/error";
import { SESSION_STORAGE_KEYS } from "../../Storage/modules/SessionStorage/constants/SessionStorage.constant";

export const fetchCreatorConfig = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      let config = {};
      config = await dataProvider.custom_request(FETCH_CREATOR_CONFIG_API);
      if (isRequestSuccessful(config.status) && !is_empty(config.data)) {
        config = config.data;
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.CREATOR_CONFIG,
          JSON.stringify(config)
        );
      }
      return resolve(config);
    } catch (error) {
      logError({
        error,
        when: "fetchCreatorConfig",
        occuredAt:
          "src/features/Common/modules/CreatorData/utils/CreatorData.utils.js",
      });
    }
  });
};

export const getCreatorConfig = () => {
  if (!is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.CREATOR_CONFIG))) {
    return JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.CREATOR_CONFIG)
    );
  } else return {};
};
