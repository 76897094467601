export const SESSION_STORAGE_KEYS = {
  CREATOR_CONFIG: "CREATOR_CONFIG",
  EMAIL_DRAFT_DATA: "draft_data", // stores important creator related config which has to fetched on every load
  USER_CHECKS: "user_checks", // stores an object with boolean key value pairs indicating the creator level checks for features
  HAS_LISTING: "HasListing", // stores a boolean, if creator has listing or not.
  ROLE_POWERS: "role_powers", // stores role powers for the user
  MENU_PERMISSIONS: "menu_permissions", // stores an object that has key value pairs indicating what to show or hide in Menu Items
  UTM_PARAMS: "utm_params", // stores urm params
  AFFILIATE_PAYOUT_REQUEST_ID: "AFFILIATE_PAYOUT_REQUEST_ID", // request to id to fetch paginated affiliate payout list
};
