import { useParams } from "react-router-dom";
import { extractIdFromURL } from "./AffiliateMarketing.utils";
import { useSelector } from "react-redux";

export const useGetAffiliateUuid = () => {
  const affliateDetail = useSelector(
    (state) => state.affiliateMarketing.affliateDetail
  );
  const { id } = useParams();

  const affiliateUuid = id ?? affliateDetail?.uuid ?? extractIdFromURL();

  return { affiliateUuid };
};
