import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { VALIDATE_COMMISSION_PAYOUT } from "features/AffiliateMarketing/constants/Affliates.api";
import { dataProvider } from "data";

export const validateCommissionPayout = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    VALIDATE_COMMISSION_PAYOUT,
    apiMethods.POST,
    payload
  );
  if (!isRequestSuccessful(status)) {
    const errorData = data;
    throw {
      message: `Error validating commission payout: ${data} ${status}`,
      errorData,
    };
  }
  return data;
};
