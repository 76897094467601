import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { ICheckoutPageFormDetails } from "../CheckoutPageSections/interfaces/CheckoutPageSections.interfaces";
import { is_empty } from "utils/validations";
import { checkout_page_reducer_keys } from "features/CheckoutPages/constants/CheckoutPagesActions.constants";

const { isEmptyHtmlFromString } = RichTextEditorUtils;

export const checkout_page_edit_panel_keys = {
  details: "details",
  appearance: "appearance",
  order_bumps: "order_bumps",
};

export const checkout_page_edit_panel_tabs = {
  details: {
    id: 1,
    label: "Details",
    value: checkout_page_edit_panel_keys.details,
  },
  appearance: {
    id: 2,
    label: "Appearance",
    value: checkout_page_edit_panel_keys.appearance,
  },
  order_bumps: {
    id: 3,
    label: "Order Bumps",
    value: checkout_page_edit_panel_keys.order_bumps,
  },
};

export const default_checkout_page_selected_tab = {
  id: 1,
  label: "Details",
  value: "details",
};

export const checkout_page_sections_keys = {
  offer_details: "offer_details",
  cover_image: "cover_image",
  description_section: "description_section",
  button_text: "button_text",
  color_palette: "color_palette",
  tab_assets: "tab_assets",
  order_bumps_section_heading: "order_bumps_section_heading",
  order_bumps_section: "order_bumps_section",
};

export const checkout_page_sections_is_edited_helpers = {
  [checkout_page_reducer_keys.listing_details]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return Boolean(
      !isEmptyHtmlFromString(
        checkoutPageFormDetails?.listingDetails?.listingTitle || ""
      ) ||
        !isEmptyHtmlFromString(
          checkoutPageFormDetails?.listingDetails?.creatorName || ""
        )
    );
  },
  [checkout_page_reducer_keys.cover_section]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return Boolean(checkoutPageFormDetails?.coverSection?.coverImage);
  },
  [checkout_page_reducer_keys.description_section]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return Boolean(
      !isEmptyHtmlFromString(
        checkoutPageFormDetails?.descriptionSection?.description || ""
      )
    );
  },
  [checkout_page_reducer_keys.button_text_section]: (
    checkoutPageFormDetails: Partial<Partial<ICheckoutPageFormDetails>>
  ) => {
    return Boolean(checkoutPageFormDetails?.buttonTextSection?.buttonType);
  },
  [checkout_page_reducer_keys.color_palette]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return !is_empty(checkoutPageFormDetails.colorPalette);
  },
  [checkout_page_reducer_keys.tab_assets]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return Boolean(
      checkoutPageFormDetails.tabAssets?.tabIcon ||
        checkoutPageFormDetails.tabAssets?.tabTitle
    );
  },
  [checkout_page_reducer_keys.order_bumps_heading_section]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    // Todo: Need to do this after getting meta data
    return Boolean(checkoutPageFormDetails);
  },
  [checkout_page_reducer_keys.order_bumps_details]: (
    checkoutPageFormDetails: Partial<ICheckoutPageFormDetails>
  ) => {
    return Boolean(checkoutPageFormDetails?.orderBumpsDetails?.orderBumpsCount);
  },
};

export const checkout_page_edit_config = {
  [checkout_page_edit_panel_keys.details]: [
    {
      id: 1,
      label: "Offer Details",
      value: checkout_page_reducer_keys.listing_details,
    },
    {
      id: 2,
      label: "Cover Image",
      value: checkout_page_reducer_keys.cover_section,
    },
    {
      id: 3,
      label: "Description",
      value: checkout_page_reducer_keys.description_section,
    },
    {
      id: 4,
      label: "Button Text",
      value: checkout_page_reducer_keys.button_text_section,
    },
  ],
  [checkout_page_edit_panel_keys.appearance]: [
    {
      id: 1,
      label: "Color Palette",
      value: checkout_page_reducer_keys.color_palette,
    },
    {
      id: 2,
      label: "Tab Assets",
      value: checkout_page_reducer_keys.tab_assets,
    },
  ],
  [checkout_page_edit_panel_keys.order_bumps]: [
    {
      id: 1,
      label: "Order Bumps Section Heading",
      value: checkout_page_reducer_keys.order_bumps_heading_section,
    },
    {
      id: 2,
      label: "Order Bumps",
      value: checkout_page_reducer_keys.order_bumps_details,
    },
  ],
};
