import {
  SHORT_MONTH_DATE,
  YEAR_MONTH_DAY_FORMAT,
} from "features/Common/modules/DateTime/DateTime.constants";
import { date_range_select_options } from "../constants/AffiliateMarketing.constants";
import { format, subDays } from "date-fns";
import { convertStringToDateObject } from "features/Common/modules/DateTime/utils/DateTime.utils";
import {
  MAX_2_DECIMAL_NUMBER_REGEX,
  UUID_REGEX,
} from "features/Common/modules/Form/constants/Form.regex";

export const getDateRange = (range) => {
  if (range === date_range_select_options.custom.value) {
    return { startDate: null, endDate: null };
  } else {
    return {
      startDate: format(
        subDays(new Date(), date_range_select_options[range]?.days),
        YEAR_MONTH_DAY_FORMAT
      ),
      endDate: format(new Date(), YEAR_MONTH_DAY_FORMAT),
    };
  }
};

export const formatDateRange = (startDate, endDate) => {
  const startParsedDate = convertStringToDateObject({ dateString: startDate });
  const startFormattedDate = format(startParsedDate, SHORT_MONTH_DATE);

  const endParsedDate = convertStringToDateObject({ dateString: endDate });
  const endFormattedDate = format(endParsedDate, SHORT_MONTH_DATE);
  return `${startFormattedDate} - ${endFormattedDate}`;
};

export const extractIdFromURL = (url = window.location.href) => {
  const match = url.match(UUID_REGEX);
  return match ? match[0] : "";
};

export const validatePercentage = (value) => {
  const numberValue = parseFloat(value);

  if (isNaN(numberValue)) return "Invalid Number";
  if (numberValue <= 0) {
    return "Value must be greater than 0";
  } else if (numberValue > 100) {
    return "Value must be less than or equal to 100";
  } else if (!MAX_2_DECIMAL_NUMBER_REGEX.test(value))
    return "Value must have at most 2 decimal places";

  return undefined;
};
