export const CHECKOUT_PAGES_ACTION_TYPES = {
  SET_CHECKOUT_DATA: "@CHECKOUT_PAGES/SET_CHECKOUT_DATA",
  SET_BASIC_DATA: "@CHECKOUT_PAGES/SET_BASIC_DATA",
  SET_FORM_DATA: "@CHECKOUT_PAGES/SET_FORM_DATA",
  SET_FORM_META_DATA: "@CHECKOUT_PAGES/SET_FORM_META_DATA",
  SET_LISTING_DETAILS_DATA: "@CHECKOUT_PAGES/SET_LISTING_DETAILS_DATA",
  SET_DESCRIPTION_SECTION_DATA: "@CHECKOUT_PAGES/SET_DESCRIPTION_SECTION_DATA",
  SET_COVER_SECTION_DATA: "@CHECKOUT_PAGES/SET_COVER_SECTION_DATA",
  SET_BUTTON_TEXT_SECTION_DATA: "@CHECKOUT_PAGES/SET_BUTTON_TEXT_SECTION_DATA",
  SET_COLOR_PALETTE_SECTION_DATA:
    "@CHECKOUT_PAGES/SET_COLOR_PALETTE_SECTION_DATA",
  SET_DEFAULT_COLOR_PALETTE_DATA:
    "@CHECKOUT_PAGES/SET_DEFAULT)COLOR_PALETTE_DATA",
  SET_TAB_ASSET_SECTION_DATA: "@CHECKOUT_PAGES/SET_TAB_ASSET_SECTION_DATA",
  SET_ORDER_BUMPS_HEADING_SECTION:
    "@CHECKOUT_PAGES/SET_ORDER_BUMPS_HEADING_SECTION",
  SET_ORDER_BUMPS_SECTION_DATA: "@CHECKOUT_PAGES/SET_ORDER_BUMPS_SECTION_DATA",
};

export const checkout_page_reducer_keys = {
  basic_details: "basicDetails",
  form_details: "formDetails",
  form_meta_details: "formMetaDetails",
  listing_details: "listingDetails",
  description_section: "descriptionSection",
  cover_section: "coverSection",
  button_text_section: "buttonTextSection",
  color_palette: "colorPalette",
  tab_assets: "tabAssets",
  default_color_palette: "defaultColorPalette",
  order_bumps_heading_section: "orderBumpsHeadingSection",
  order_bumps_details: "orderBumpsDetails",
} as const;
