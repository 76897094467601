import { put, call, select, takeLatest } from "redux-saga/effects";
import { is_empty } from "utils/validations";
import { dataProvider } from "data";
import {
  FETCH_CART_API,
  FETCH_CREATOR_ACTIVE_PLAN_API,
  FETCH_FEATURE_ACCESSIBILITY_API,
  FETCH_FEATURE_CREDITS_API,
  FETCH_FEATURE_PRICING_DETAILS_API,
  FETCH_HIGHLIGHTS_API,
  FETCH_LEDGER_TRIGGERS_API,
} from "../Account.constants";
import { isRequestSuccessful } from "utils/Utils";
import { apiMethods } from "data/api.constants";
import { getCreditExpiryStatus } from "common/Components/UBP/ubp.utils";
import { isInternationalTimezone } from "utils/AuthUtil";
import { logError } from "utils/error";
import {
  EXLY_ACCOUNT__GET_CART,
  EXLY_ACCOUNT__GET_CREATOR_ACTIVE_PLAN,
  EXLY_ACCOUNT__GET_FEATURE_CREDITS,
  EXLY_ACCOUNT__GET_LEDGER_TRIGGERS,
  EXLY_ACCOUNT__SET_CART,
  EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN,
  EXLY_ACCOUNT__SET_FEATURE_CREDITS,
  EXLY_ACCOUNT__SET_LEDGER_TRIGGERS,
} from "ui/pages/Account/redux/actions";
import {
  EXLY_ACCOUNT__GET_FEATURE_ACCESSIBILITY,
  EXLY_ACCOUNT__GET_FEATURE_DETAILS,
  EXLY_ACCOUNT__GET_HIGHLIGHTED_FEATURE,
  EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY,
  EXLY_ACCOUNT__SET_FEATURE_DETAILS,
  EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE,
} from "./Account.actions";

function* fetchFeatureCredits({
  feature_key,
  successCallback,
  errorCallback,
  forceUpdate,
}) {
  try {
    if (is_empty(feature_key) || isInternationalTimezone()) return;
    const featureCredits = yield select(
      (state) => state.myAccount.featureCredits[feature_key]
    );
    if (is_empty(featureCredits) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_FEATURE_CREDITS_API,
        apiMethods.GET,
        {
          feature_key,
        }
      );
      if (isRequestSuccessful(response.status)) {
        const credits_expiry_status = getCreditExpiryStatus(response.data);
        yield put({
          type: EXLY_ACCOUNT__SET_FEATURE_CREDITS,
          payload: {
            data: { ...response.data, credits_expiry_status },
            feature_key,
          },
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_FEATURE_CREDITS_API} FAIL`;
      }
    } else {
      const credits_expiry_status = getCreditExpiryStatus(featureCredits);
      yield put({
        type: EXLY_ACCOUNT__SET_FEATURE_CREDITS,
        payload: {
          data: { ...featureCredits, credits_expiry_status },
          feature_key,
        },
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchFeatureCredits",
      occuredAt: "src/features/Account/redux/Account.saga.js",
    });
    yield put({
      type: EXLY_ACCOUNT__SET_FEATURE_CREDITS,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchFeatureAccessibility({
  featureKey,
  successCallback,
  errorCallback,
  forceUpdate,
}) {
  try {
    if (is_empty(featureKey)) return;
    const featureAccessibilty = yield select(
      (state) => state.myAccount.featureAccessibilty
    );
    if (
      !forceUpdate &&
      !is_empty(featureAccessibilty) &&
      featureKey in featureAccessibilty
    ) {
      yield put({
        type: EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY,
        payload: {
          featureKey,
          accessibility: featureAccessibilty[featureKey],
        },
      });
    } else {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_FEATURE_ACCESSIBILITY_API,
        apiMethods.GET,
        {
          feature_key: featureKey,
        }
      );

      if (isRequestSuccessful(response.status)) {
        yield put({
          type: EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY,
          payload: {
            featureKey,
            accessibility: response?.data?.is_accessible,
          },
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY} FAIL`;
      }
    }
  } catch (error) {
    logError({
      error,
      when: "fetchFeatureAccessibility",
      occuredAt: "src/features/Account/redux/Account.saga.js",
    });
    yield put({
      type: EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchCreatorActivePlan({
  successCallback,
  errorCallback,
  forceUpdate,
}) {
  try {
    const creatorPlan = yield select((state) => state.myAccount.creatorPlan);
    if (is_empty(creatorPlan) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_CREATOR_ACTIVE_PLAN_API
      );
      if (isRequestSuccessful(response.status)) {
        yield put({
          type: EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN,
          payload: response?.data?.current_plan,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_CREATOR_ACTIVE_PLAN_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN,
        payload: creatorPlan,
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchCreatorActivePlan",
      occuredAt: "src/features/Account/redux/Account.saga.js",
    });
    yield put({
      type: EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchHighlightedFeature({
  successCallback,
  errorCallback,
  forceUpdate,
}) {
  try {
    const highlightedFeature = yield select(
      (state) => state.myAccount.highlights
    );
    if (is_empty(highlightedFeature) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_HIGHLIGHTS_API
      );
      if (isRequestSuccessful(response.status)) {
        yield put({
          type: EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE,
          payload: response?.data,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_HIGHLIGHTS_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE,
        payload: highlightedFeature,
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchHighlightedFeature",
      occuredAt: "src/ui/pages/Account/redux/sagas.js",
    });
    yield put({
      type: EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE,
      payload: null,
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchFeaturePricingDetails({
  successCallback,
  errorCallback,
  forceUpdate,
  featureKey,
}) {
  try {
    const featureDetails = yield select(
      (state) => state.myAccount.featureDetails?.[featureKey]
    );

    if (is_empty(featureDetails) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_FEATURE_PRICING_DETAILS_API,
        apiMethods.GET,
        { feature_key: featureKey }
      );
      if (isRequestSuccessful(response.status)) {
        yield put({
          type: EXLY_ACCOUNT__SET_FEATURE_DETAILS,
          payload: { data: response?.data, featureKey },
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_FEATURE_PRICING_DETAILS_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_ACCOUNT__SET_FEATURE_DETAILS,
        payload: { featureKey, data: featureDetails },
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchFeaturePricingDetails",
      occuredAt: "src/ui/pages/Account/redux/sagas.js",
    });
    yield put({
      type: EXLY_ACCOUNT__SET_FEATURE_DETAILS,
      payload: { featureKey, data: null },
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchCart({ successCallback, errorCallback, forceUpdate }) {
  try {
    const cart = yield select((state) => state.myAccount.cart);
    if (is_empty(cart) || forceUpdate) {
      const response = yield call(dataProvider.custom_request, FETCH_CART_API);
      if (isRequestSuccessful(response.status)) {
        const cartList = response?.data?.items;
        let payload = {};
        for (let item of cartList) {
          payload[item.feature_key] = item;
        }

        yield put({
          type: EXLY_ACCOUNT__SET_CART,
          payload,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_CART_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_ACCOUNT__SET_CART,
        payload: cart,
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchCart",
      occuredAt: "src/features/Account/redux/Account.saga.js",
    });
    yield put({
      type: FETCH_CART_API,
      payload: {},
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchLedgerTriggers({
  successCallback,
  errorCallback,
  forceUpdate,
  feature_key,
}) {
  try {
    if (is_empty(feature_key)) return;
    const ledgerTriggers = yield select(
      (state) => state.myAccount.ledgerTriggers
    );
    if (is_empty(ledgerTriggers[feature_key]) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_LEDGER_TRIGGERS_API,
        apiMethods.GET,
        { feature_key }
      );
      if (isRequestSuccessful(response.status)) {
        yield put({
          type: EXLY_ACCOUNT__SET_LEDGER_TRIGGERS,
          payload: { feature_key, data: response?.data?.trigger_map },
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_LEDGER_TRIGGERS_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_ACCOUNT__SET_LEDGER_TRIGGERS,
        payload: ledgerTriggers,
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchLedgerTriggers",
      occuredAt: "src/features/Account/redux/Account.saga.js",
    });
    yield put({
      type: FETCH_LEDGER_TRIGGERS_API,
      payload: {},
    });
    if (errorCallback) yield call(errorCallback);
  }
}

function* myAccountSaga() {
  yield takeLatest(EXLY_ACCOUNT__GET_CART, fetchCart);
  yield takeLatest(
    EXLY_ACCOUNT__GET_FEATURE_DETAILS,
    fetchFeaturePricingDetails
  );
  yield takeLatest(EXLY_ACCOUNT__GET_FEATURE_CREDITS, fetchFeatureCredits);
  yield takeLatest(
    EXLY_ACCOUNT__GET_FEATURE_ACCESSIBILITY,
    fetchFeatureAccessibility
  );
  yield takeLatest(EXLY_ACCOUNT__GET_LEDGER_TRIGGERS, fetchLedgerTriggers);
  yield takeLatest(
    EXLY_ACCOUNT__GET_HIGHLIGHTED_FEATURE,
    fetchHighlightedFeature
  );
  yield takeLatest(
    EXLY_ACCOUNT__GET_CREATOR_ACTIVE_PLAN,
    fetchCreatorActivePlan
  );
}

export default myAccountSaga;
