import {
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "./Affliates.urlPaths";

export const date_range_select_options = {
  last_7: { value: "last_7", label: "Last 7 days", days: 7 },
  last_30: { value: "last_30", label: "Last 30 days", days: 30 },
  lifetime: { value: "lifetime", label: "Lifetime", days: 90 },
  custom: { value: "custom", label: "Custom" },
};

export const AFFLIATE_STATUS = {
  pending: { id: "pending", value: 1, label: "Pending" },
  approved: { id: "approved", value: 2, label: "Approved" },
  rejected: { id: "rejected", value: 3, label: "Rejected" },
};

export const AFFILIATE_MANAGEMENT_TABS = {
  all_affiliates: {
    label: "All Affiliates",
    value: 2,
    path: `/${
      AFFILIATE_MARKETING_ROUTE_IDS[
        AFFILIATE_MARKETING_URL_KEYS.list_affliate_management
      ]
    }`,
  },
  new_affiliate: {
    label: "New Affiliate Requests",
    value: 1,
    path: `/${
      AFFILIATE_MARKETING_ROUTE_IDS[AFFILIATE_MARKETING_URL_KEYS.new_user_req]
    }`,
  },
};

export const COMMISSION_PAYOUT_STATUS_KEYS = {
  pending: 1,
  exly_initiated: 2,
  failed: 3,
  missing_bank_details: 4,
  processed: 5,
  approved: 6,
  rejected: 7,
  payout_pending: 8,
};

export const COMMISSION_PAYOUT_STATUS = {
  [COMMISSION_PAYOUT_STATUS_KEYS.processed]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.processed,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.processed}`,
    label: "Payout Processed",
    bgColor: "#00B779",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.missing_bank_details]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.missing_bank_details,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.missing_bank_details}`,
    label: "Missing/Incorrect bank account details",
    bgColor: "#BF2600",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.failed]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.failed,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.failed}`,
    label: "Payout Failed",
    bgColor: "#BF2600",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.pending]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.pending,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.pending}`,
    label: "Pending",
    bgColor: "#FFAB00",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.approved]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.approved,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.approved}`,
    label: "Approved",
    bgColor: "#00B779",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.exly_initiated]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.exly_initiated,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.exly_initiated}`,
    label: "Initiated",
    bgColor: "#FFAB00",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.rejected]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.rejected,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.rejected}`,
    label: "Rejected",
    bgColor: "#BF2600",
  },
  [COMMISSION_PAYOUT_STATUS_KEYS.payout_pending]: {
    value: COMMISSION_PAYOUT_STATUS_KEYS.payout_pending,
    key: `${COMMISSION_PAYOUT_STATUS_KEYS.payout_pending}`,
    label: "Payout in progress",
    bgColor: "#FFAB00",
  },
};

export const COMMISSION_PAYOUT_STATUS_FILTERS = [
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.pending],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.rejected],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.approved],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.payout_pending],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.processed],
  COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.failed],
];

export const INITIAL_STAT_VALUE = "0";

export const missing_bank_details_error_code = 1;

export const AFFILIATE_BULK_ACTION_KEYS = {
  affiliate_status: "affiliate_status",
  commission_action_type: "commission_action_type",
};
