import {
  commission_form_keys,
  commission_initial_values,
} from "../../AffiliateCommissionForm/constants/AffiliateCommissionForm.constant";

export const AFFILIATE_SETTINGS_TABS = {
  affiliate_program_setup: {
    value: "affiliate_program_setup",
    label: "Affiliate Program Setup",
  },
  commission_details: {
    value: "commission_details",
    label: "Commission Details",
  },
};

export const form_keys = {
  ...commission_form_keys,
  disable_auto_approval: {
    key: "disable_auto_approval",
    label: "Require Approval for becoming Affiliates",
    tooltipText:
      "Approval of affiliates required if enabled. Else, affiliates who sign up will be added directly.",
  },
};

export const initial_values = {
  ...commission_initial_values,
  [form_keys.disable_auto_approval.key]: true,
};
