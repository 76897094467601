import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
  EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
  EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING,
} from "./Affliates.actions";
import { is_empty } from "utils/validations";
import { dataProvider } from "data";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import {
  form_keys,
  initial_values,
} from "../modules/AffiliateSettings/constants/AffiliateSettings.constants";
import { FETCH_AFFILIATE_SETTINGS_API } from "../constants/Affliates.api";

function* fetchAffiliateSettings({
  successCallback,
  errorCallback,
  forceUpdate,
}) {
  try {
    const affiliateSettings = yield select(
      (state) => state.affiliateMarketing.affiliateSettings.formValues
    );
    yield put({
      type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING,
      payload: true,
    });
    if (is_empty(affiliateSettings) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        FETCH_AFFILIATE_SETTINGS_API
      );
      if (isRequestSuccessful(response.status)) {
        const payload = response?.data;
        if (!is_empty(payload?.[form_keys.affiliate_listing_map.key])) {
          payload[form_keys.affiliate_listing_map.key] = payload[
            form_keys.affiliate_listing_map.key
          ].map((item) => item.uuid);
        }
        yield put({
          type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
          payload: !is_empty(payload) ? payload : initial_values,
        });
        if (successCallback) yield call(successCallback);
      } else {
        throw `API: ${FETCH_AFFILIATE_SETTINGS_API} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
        payload: affiliateSettings,
      });
    }
  } catch (error) {
    logError({
      error,
      when: "fetchAffiliateSettings",
      occuredAt: "src/features/AffiliateMarketing/redux/Affliates.saga.js",
    });
    yield put({
      type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
      payload: {},
    });
    if (errorCallback) yield call(errorCallback);
  } finally {
    yield put({
      type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS_PROCESSING,
      payload: false,
    });
  }
}

function* affiliateMarketingSaga() {
  yield takeLatest(
    EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
    fetchAffiliateSettings
  );
}

export default affiliateMarketingSaga;
