import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import styles from "./BulkActionButtonStyles.module.css";
import classnames from "classnames";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

const BulkActionButton = ({
  actionItem,
  isFullLayout,
  isSmall = true,
  className,
}) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <Button
      size={isDesktop ? "medium" : "thin"}
      key={actionItem.text}
      onClick={actionItem.onClick}
      classes={{
        label: classnames(styles.btnLabelStyle, actionItem?.customClass?.label),
      }}
      className={classnames(
        isFullLayout && styles.btnWrapperFull,
        isSmall && styles.btnStyle,
        actionItem?.customClass?.label
      )}
      buttonWrapperClassName={classnames(
        isFullLayout && styles.btnWrapperFull,
        actionItem?.customClass?.wrapper,
        className
      )}
    >
      {actionItem.icon}
      {actionItem.text}
    </Button>
  );
};

export default BulkActionButton;
